import Metadata from "~/components/metadata";
import prisma from "~/lib/prisma";
import Image from "next/image";
import HomeLayout from "~/components/layouts/home";
import { BASE_SELECT } from "~/cars/api/schemas";
import { formatCarToList } from "~/cars/api/utils";
import Link from "next/link";
import LocalAds from "~/components/local-ads";
import GoogleAd from "~/common/components/GoogleAd";
import { ADS } from "~/common/constants/googleAds";
import CarouselBanner from "~/components/CarouselBanner";
import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

import Card from "~/components/card";
import ElementsSection from "~/components/element-section";

function StartPage({ className }) {

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
      const getItem = async () => {
          const open = await  !sessionStorage.getItem('beenOpen')
          setIsOpen(open)
      }
      getItem()
  }, [])

  const modalStyles = {
      container: {
          display: 'flex',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          maxWidth: '1000px'
      },
      item: {
          dispaly: 'flex',
          // width: '700px',
          padding: '0px 10px'
      },
      image: {
          // width: '700px'
      }
  }

  const handleClose = () => {
      sessionStorage.setItem('beenOpen', true)
      setIsOpen(false)
  }

  return (

      <Dialog
          open={isOpen}
          onClose={handleClose}
          as="div"
          className={clsx(
              "fixed inset-0 z-10 flex items-center justify-center ",
              {
                  "bg-brand-primary bg-opacity-70": isOpen,
              }
          )}
      >
          <div
              className={clsx(
                  "flex flex-col rounded-lg relative bg-gray-50 text-black py-8 px-8 text-center",
                  className
              )}
          >
              <div className="absolute top-3 right-0">
                  <button
                      className="m-4 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm p-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleClose}
                  >
                      <FaTimes fontSize={12} />
                  </button>
              </div>
              <Dialog.Overlay />
              <Dialog.Title className="text-brand-dark text-3xl">
                        </Dialog.Title>


              <div style={modalStyles.container}>
                  <div style={modalStyles.item}><p>Desea entrar a </p><a href="https://www.inmueblessonora.com/"><img style={modalStyles.image} src="/images/modal_image_inmuebles.jpeg" alt="" /></a></div>
                  <div style={modalStyles.item}><p>O permanecer en</p><a onClick={handleClose}><img style={modalStyles.image} src="/images/modal_image_seminuevos.jpeg" alt="" /></a></div>
              </div>
          </div>
      </Dialog>
  )
}


export default function Home({
  lastAdded,
  lastRecomended,
  lastLotesAdded,
  lastAgenciasAdded,
}) {
  return (
    <div>
      <Metadata />
      <main className="flex w-full flex-col md:flex-row gap-4">
        <div className="w-12/12 md:w-10/12">
          <HomeLayout>
            <a href="https://seminuevossonora.com/revista">
              <CarouselBanner
                images={[
                  {
                    id: 1,
                    urlImage: "/images/banners/banner_vista_miramar.jpg",
                  },{
                    id: 1,
                    urlImage: "/images/banners/banner_vista_te_interesa.jpeg",
                  },
                ]}
              />
            </a>
            <ElementsSection
              title="Autos Recomendados"
              more={{
                text: "Ver todos los autos recomendados",
                url: `/autos/recomendados`,
              }}
            >
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {lastRecomended.map((car) => {
                  const metas = [
                    {
                      value: car.type,
                      link: `/autos/?type=${car.type}`,
                      borderless: false,
                      key: "type",
                    },
                    {
                      value: car.brand,
                      link: `/autos/?brand=${car.brand}`,
                      borderless: false,
                      key: "brand",
                    },
                    {
                      value: car.year,
                      link: `/autos/?year=${car.year}`,
                      borderless: false,
                      key: "year",
                    },
                    {
                      value: car.transmission,
                      link: `/autos/?transmission=${car.transmission}`,
                      borderless: false,
                      key: "transmission",
                    },

                    {
                      value: car.city,
                      link: `/autos/?city=${car.city}`,
                      borderless: false,
                      key: "city",
                    },

                    {
                      value: car.state,
                      link: `/autos/?state=${car.state}`,
                      borderless: false,
                      key: "state",
                    },
                  ];
                  return (
                    <Card
                      key={car.id}
                      car={car.title}
                      id={car.id}
                      slug={car.slug}
                      cover={
                        car.cover ||
                        "https://seminuevossonora.mx/inicio/autos/1645116654/1645116654_FIGO-3.jpg"
                      }
                      price={car.price}
                      metas={metas}
                      isRecomended={car.isPremium}
                    />
                  );
                })}
              </div>
            </ElementsSection>
<a>
              <CarouselBanner
                images={[
                  {
                    id: 1,
                    urlImage: "/images/banners/banner_intermedio.jpg",
                  },
                ]}
              />
            </a>

            <GoogleAd adSlot={ADS.PRADA} />

            <ElementsSection
              title="Autos Recientes"
              more={{
                text: "Ver todos los autos",
                url: `/autos`,
              }}
            >
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {lastAdded.map((car) => {
                  const metas = [
                    {
                      value: car.type,
                      link: `/autos/?type=${car.type}`,
                      borderless: false,
                      key: "type",
                    },
                    {
                      value: car.brand,
                      link: `/autos/?brand=${car.brand}`,
                      borderless: false,
                      key: "brand",
                    },
                    {
                      value: car.year,
                      link: `/autos/?year=${car.year}`,
                      borderless: false,
                      key: "year",
                    },
                    {
                      value: car.transmission,
                      link: `/autos/?transmission=${car.transmission}`,
                      borderless: false,
                      key: "transmission",
                    },

                    {
                      value: car.city,
                      link: `/autos/?city=${car.city}`,
                      borderless: false,
                      key: "city",
                    },

                    {
                      value: car.state,
                      link: `/autos/?state=${car.state}`,
                      borderless: false,
                      key: "state",
                    },
                  ];
                  return (
                    <Card
                      id={car.id}
                      car={car.title}
                      slug={car.slug}
                      cover={
                        car.cover ||
                        "https://seminuevossonora.mx/inicio/autos/1645116654/1645116654_FIGO-3.jpg"
                      }
                      price={car.price}
                      metas={metas}
                      isRecomended={car.isPremium}
                      key={car.id}
                    />
                  );
                })}
              </div>
            </ElementsSection>

            <ElementsSection
              title="Lotes"
              more={{ text: "+ lotes", url: `/negocios?category=Lote` }}
            >
              <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 text-center">
                {lastLotesAdded.map((lote) => {
                  return (
                    <Link
                      href={`/negocios/${lote.slug}`}
                      className="cursor-pointer text-center"
                      key={lote.slug}
                    >
                      <a>
                        <Image
                          className="rounded-full border-2 border-white"
                          src={lote.avatar || "/images/icon_placeholder.png"}
                          alt={lote.business}
                          width={142}
                          height={142}
                        />
                      </a>
                    </Link>
                  );
                })}
              </div>
            </ElementsSection>

            <ElementsSection
              title="Agencias"
              more={{
                text: "+ agencias",
                url: `/negocios?category=Agencia Seminuevos`,
              }}
            >
              <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 text-center">
                {lastAgenciasAdded.map((agencia) => {
                  return (
                    <Link
                      href={`/negocios/${agencia.slug}`}
                      className="cursor-pointer text-center"
                      key={agencia.slug}
                    >
                      <a>
                        <Image
                          className="rounded-full border-2 border-white"
                          src={agencia.avatar || "/images/icon_placeholder.png"}
                          alt={agencia.business}
                          width={128}
                          height={128}
                        />
                      </a>
                    </Link>
                  );
                })}
              </div>
            </ElementsSection>
            <GoogleAd adSlot={ADS.HORIZONTAL_AD} />
            <StartPage />
          </HomeLayout>
        </div>
        <div className="w-12/12 md:w-2/12">
          <LocalAds />
          <GoogleAd adSlot={ADS.VERTICAL_AD_1} />
          <GoogleAd adSlot={ADS.VERTICAL_AD_2} />
        </div>
      </main>
    </div>
  );
}

export async function getServerSideProps() {
  const lastCarsAded = await prisma.car.findMany({
    where: {
      status: 3, //Publicados
    },
    take: 3,
    select: BASE_SELECT,
    orderBy: {
      // createdAt: "desc",
      publishedAt: "desc",
    },
  });
  const lastRecomendedCarsAdded = await prisma.car.findMany({
    where: {
      status: 3, //Publicado
      AND: {
        planId: 1,
      },
    },
    take: 3,
    select: BASE_SELECT,
    orderBy: {
      publishedAt: "desc",
    },
  });

  const lastLotesAdded = await prisma.business.findMany({
    where: {
      status: 1, // Activo
      line: 1, //Lote
      id: {
        not: 91, // Anonimo?
      },
    },
    take: 8,
    select: {
      slug: true,
      avatar: true,
      business: true,
    },
    orderBy: {
      createdAt: "desc",
    },
  });

  const lastAgenciasAdded = await prisma.business.findMany({
    where: {
      status: 1, // Activo
      line: 2, //Lote
    },
    take: 8,
    select: {
      slug: true,
      avatar: true,
      business: true,
    },
    orderBy: {
      createdAt: "desc",
    },
  });

  return {
    props: {
      lastRecomended: lastRecomendedCarsAdded.map(formatCarToList),
      lastAdded: lastCarsAded.map(formatCarToList),
      lastLotesAdded: lastLotesAdded,
      lastAgenciasAdded,
    },
  };
}
